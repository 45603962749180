
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component
export default class MaintenanceApp extends Vue {
    templatePage: string = '';

    created() {
        this.templatePage = this.$store.getters.getMaintenanceTemplate;

        if (!this.templatePage) {
            this.$router.push('/login');
        } else {
            const existScript = document.getElementById('page-script');
            if (!existScript) {
                const indexStart = this.templatePage.indexOf('<script>') + 8;
                const indexEnd = this.templatePage.indexOf('<\/script>');
                const script = this.templatePage.substring(indexStart, indexEnd);
                const pageScript = document.createElement('script');

                this.templatePage = this.templatePage.replace(script, '');

                pageScript.id = 'page-script';
                pageScript.innerHTML = script;

                setTimeout(() => {
                    document.body.appendChild(pageScript);
                }, 0);
            }
        }
    }

    beforeDestroy() {
        if (this.templatePage) {
            location.reload();
        }
    }
}
