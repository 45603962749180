
import { Prop, Watch, Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import DescriptionApp from '@/components/DescriptionApp.vue';
import LocaleMixin from '@/mixins/locale';

@Component({
    components: {
        DescriptionApp,
    },
})
export default class VerificationApp extends LocaleMixin {
    @Prop({ default: '' }) email!: string;
    @Prop({ default: '' }) prev!: string;

    successResend: boolean = false;
    errorResend: boolean = false;
    responseMessage: string = '';
    disableButton: boolean = false;
    passwordRecovery: boolean = false;
    changeLang: boolean = false;

    get serverUrl() {
        return this.$store.getters.getServerUrl;
    }

    backToLogin() {
        this.$router.push({ name: 'auth' });
    }

    resendEmail(withoutSuccess?: boolean) {
        console.log('resendEmail')
        this.disableButton = true;

        axios.get(this.serverUrl + '/authservice/resendverificationemail?email=' + this.email).then(() => {
            this.successResend = true;
            if (!withoutSuccess) { this.responseMessage = `In case ${this.email} address is in our database, you will receive a password recovery link to your email address in a few minutes` }

            setTimeout(() => { this.responseMessage = '' }, 5000)
            this.disableButton = false;
        }).catch(() => {
            this.successResend = false;
            this.responseMessage = 'Something went wrong';

            setTimeout(() => { this.responseMessage = '' }, 5000)
            this.disableButton = false;
        });
    }

    resendPassword() {
        console.log('resendPassword')
        this.disableButton = true;

        console.log(this.email)

        axios.get(this.serverUrl + '/authservice/changepassword?email=' + this.email).then(() => {
            this.successResend = true;
        }).catch((error) => {
            console.log('catch');
            this.successResend = false;
            this.disableButton = false;

            if (error.response.status === 401) {
                this.responseMessage = 'This email is invalid or has expired';
            } else {
                this.responseMessage = 'Something went wrong';
                console.log(this.responseMessage)
            }
        });

        this.disableButton = false;
    }

    created() {
        if (!this.email && this.$route.name !== 'password-recovery') {
            this.$router.push({ name: 'auth' });
        }
        if (this.prev === 'true') { this.resendEmail(true); }

        this.passwordRecovery = this.$route.name === 'password-recovery' ? true : false;
    }
}
