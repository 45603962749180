
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import vueCustomScrollbar from 'vue-custom-scrollbar';

@Component({
    components: {
        vueCustomScrollbar,
    },
})
export default class ServicesModal extends Vue {
    @Prop({ default: false }) showModal!: boolean;
    @Prop({ default: ''}) title!: string;

    modalState: boolean = false;
    scrollSettings: object = {
        suppressScrollX: true,
    };

    @Watch('showModal')
    onModalChange() {
        this.modalState = this.showModal;
    }
}
