import Vue from 'vue';
import Router from 'vue-router';
import Login from './pages/login/Login.vue';
import Redirect from './pages/main/Redirect.vue';
import Registration from './pages/registration/Registration.vue';
import ForgotPass from './pages/forgotPass/ForgotPass.vue';
import VerificationApp from './pages/verificationApp/VerificationApp.vue';
import Maintenance from './pages/maintenance/MaintenanceApp.vue';
import AuthorizationPage from './pages/authorize/AuthorizationPage.vue';
Vue.use(Router)
export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: Redirect,
        },
        {
            path: '/maintenance',
            component: Maintenance,
        },
        {
            path: '/login',
            component: Login,
            name: 'auth',
            props: (route) => ({ status: route.query.status, token: route.query.token }),
        },
        {
            path: '/redirected/:applicationId?',
            component: Redirect,
        },
        {
            path: '/forgot-pass',
            component: ForgotPass,
            name: 'forgot-pass',
            props: (route) => ({ userId: route.query.token, email: route.query.email }),
        },
        {
            path: '/email-verification',
            component: VerificationApp,
            name: 'email-verification',
            props: (route) => ({ email: route.query.email, prev: route.query.prev }),
        },
        {
            path: '/password-recovery',
            component: VerificationApp,
            name: 'password-recovery',
            props: (route) => ({ email: route.query.email }),
        },
        {
            path: '/signup',
            component: Registration,
            name: 'signup',
            props: (route) => ({ signUpType: route.query.signUpType }),
        },
        {
            path: '/verif',
            component: VerificationApp,
        },
        {
            path: '/authorize',
            component: AuthorizationPage,
            props: (route) => ({ businessName: route.query.name, email: route.query.email }),
        }
    ]
})