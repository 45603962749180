
import { Prop, Watch, Component, Vue } from 'vue-property-decorator';

@Component
export default class Form extends Vue {
    @Prop({ default: () => { } }) formData!: object;
    @Prop({ default: () => { } }) validation!: (formName: string, input?: string) => void;

    formName: string = 'legalEntityForm';
}
