import Vue from 'vue';
import Component from 'vue-class-component';

declare interface Languages {
    options: object[],
    selected: {
        lang: string;
        label: string;
        id: string;
    },
};

@Component
export default class Locale extends Vue {
    langs: Languages = {
        options: [
            {
                lang: 'en',
                label: 'ENG',
                id: 'f94e94e2-679a-11ea-8c63-0242ac110003',
            },
            {
                lang: 'uk',
                label: 'УКР',
                id: '03b87221-679b-11ea-8c63-0242ac110003',
            },
            {
                lang: 'ru',
                label: 'РУС',
                id: 'ff2c3206-679a-11ea-8c63-0242ac110003',
            },
        ],
        selected: {
            lang: 'en',
            label: 'ENG',
            id: 'f94e94e2-679a-11ea-8c63-0242ac110003',
        },
    };

    created() {
        if (localStorage.LangAval) {
            this.langs.selected = JSON.parse(localStorage.LangAval);
        }

        if (localStorage.LangAval) {
            this.$i18n.locale = this.langs.selected.lang;
        }
    }
}