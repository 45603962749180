
import { Prop, Watch, Component, Vue } from 'vue-property-decorator';
import LocaleMixin from '@/mixins/locale';
import Form from '@/components/Form.vue';
import axios from 'axios';

declare interface EntityData {
    responseError: string;
    name: InputItem,
    businessName: InputItem;
    accountId: InputItem;
    tin: InputItem;
    email: InputItem;
    password: InputItem;
}
declare interface AuthObject {
    mobilePhone?: string;
    businessName?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    email?: string;
    identifierType?: string;
    identifier?: string;
}
declare interface RequestBody {
    FirstName: string | boolean;
    LastName: string | null | boolean;
    MiddleName: string | null | boolean;
    BusinessName: string | null | boolean;
    AccountId: string | null | boolean;
    Identifier: string | boolean;
    IdentifierType: string | boolean;
    Email: string | boolean;
    Password: string | boolean;
    Language: string | null;
}
declare interface InputItem {
    value: string | boolean;
    error: boolean;
    errorLabel: string;
    inputType?: string;
};

@Component({
    components: {
        Form,
    },
})
export default class SignUp extends LocaleMixin {
    @Prop({ default: '' }) serverUrl!: string;
    @Prop({ default: '' }) signUpType!: string;
    @Prop({ default: '' }) identifierType!: string;

    sitekey: string = '6Lcl2aIZAAAAAIMaTe1gX12-60QSrMr7C7WsCtYy';
    disableButton: boolean = false;
    legalEntityForm: EntityData = {
        responseError: '',
        businessName: {
            value: '',
            error: false,
            errorLabel: '',
        },
        accountId: {
            value: '',
            error: false,
            errorLabel: '',
        },
        name: {
            value: '',
            error: false,
            errorLabel: '',
        },
        tin: {
            value: '',
            error: false,
            errorLabel: '',
        },
        email: {
            value: '',
            error: false,
            errorLabel: '',
        },
        password: {
            value: '',
            error: false,
            errorLabel: '',
            inputType: 'password',
        },
    }

    /**
     * reseting form data
     * 
     * @param {string} formName? specific form to reseting
     */
    resetFormData(formName?: string) {
        if (!formName) {
            this.legalEntityForm = {
                responseError: '',
                businessName: {
                    value: '',
                    error: false,
                    errorLabel: '',
                },
                accountId: {
                    value: '',
                    error: false,
                    errorLabel: '',
                },
                name: {
                    value: '',
                    error: false,
                    errorLabel: '',
                },
                tin: {
                    value: '',
                    error: false,
                    errorLabel: '',
                },
                email: {
                    value: '',
                    error: false,
                    errorLabel: '',
                },
                password: {
                    value: '',
                    error: false,
                    errorLabel: '',
                    inputType: 'password',
                },
            };
        }
    }
    setStoredAuthParams() {
        let storedAuthData: AuthObject | null;
        const type = this.signUpType.toLowerCase();
        this.legalEntityForm.businessName.value = localStorage.getItem('businessName') ?? "";
        this.legalEntityForm.accountId.value = localStorage.getItem('accountId') ?? "";
        this.legalEntityForm.email.value = localStorage.getItem('email') ?? "";

        try {
            const authData = localStorage.getItem('fsnb_sign-in_data') as string;
            storedAuthData = JSON.parse(authData) as AuthObject;
        }
        catch {
            storedAuthData = null;
        }

        switch (type) {
            case 'tin': {

                if (storedAuthData) {
                    this.legalEntityForm.email.value = storedAuthData.email ? storedAuthData.email : '';
                    this.legalEntityForm.tin.value = storedAuthData.identifier ? storedAuthData.identifier : '';
                    this.legalEntityForm.businessName.value = storedAuthData.businessName ? storedAuthData.businessName : '';
                    this.legalEntityForm.accountId.value = '';
                }

                break;
            }
            default: {
                break;
            }
        }
    }
    /**
     * validation email field
     * 
     * @param {string} email
     * @return {boolean} result
     */
    validEmail(email: string) {
        const re = /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    /**
     * get validation result
     * 
     * @param {string} formName
     * @param {string} input? speccific input
     * @return {boolean | undefined} result
     */
    validation(formName: string, input?: string) {
        if (formName === 'legalEntityForm') {
            const passwordValidation = () => {
                const validationArray = [
                    ((this[formName].password.value as string).length >= 8),
                    /[A-Z]/.test(this[formName].password.value as string),
                    /[a-z]/.test(this[formName].password.value as string),
                    /[0-9]/.test(this[formName].password.value as string),
                    /[!@#$_.,]/.test(this[formName].password.value as string),
                ];

                if (!this[formName].password.value) {
                    this[formName].password.error = true;
                    this[formName].password.errorLabel = 'Field is required.';
                } else if (!validationArray.every(item => item)) {
                    this[formName].password.error = true;
                    this[formName].password.errorLabel = 'Password must contain: 8 or more characters, uppercase and lowercase Latin letters, numbers, special characters (! @ # $ _.,).';
                } else {
                    this[formName].password.error = false;
                    this[formName].password.errorLabel = '';
                }
            }
            const identifierValidation = () => {
                if (!this.legalEntityForm.tin.value) {
                    this.legalEntityForm.tin.error = true;
                    this.legalEntityForm.tin.errorLabel = 'Field is required.';
                } else if ((this.legalEntityForm.tin.value as string).length !== 9) {
                    this.legalEntityForm.tin.error = true;
                    this.legalEntityForm.tin.errorLabel = 'The field must contain 9 characters.';
                } else {
                    this.legalEntityForm.tin.error = false;
                    this.legalEntityForm.tin.errorLabel = '';
                }
            }
            const emailValidation = () => {
                if (!this[formName].email.value) {
                    this[formName].email.error = true;
                    this[formName].email.errorLabel = 'Field is required.';
                } else if (!this.validEmail(this[formName].email.value as string)) {
                    this[formName].email.error = true;
                    this[formName].email.errorLabel = 'Invalid E-mail format.';
                } else {
                    this[formName].email.error = false;
                    this[formName].email.errorLabel = '';
                }
            }
            const nameValidation = () => {
                if (formName === 'legalEntityForm' && !this[formName].name.value) {
                    this[formName].name.error = true;
                    this[formName].name.errorLabel = 'Field is required.';
                } else if (formName === 'legalEntityForm') {
                    this[formName].name.error = false;
                    this[formName].name.errorLabel = '';
                }
            }

            const businessNameValidation = () => {
                if (formName === 'legalEntityForm' && !this[formName].businessName.value) {
                    this[formName].businessName.error = true;
                    this[formName].businessName.errorLabel = 'Field is required.';
                } else if (formName === 'legalEntityForm') {
                    this[formName].businessName.error = false;
                    this[formName].businessName.errorLabel = '';
                }
            }

            switch (input) {
                case 'password': {
                    passwordValidation();
                    break;
                }
                case 'name': {
                    nameValidation();
                    break;
                }
                case 'businessName': {
                    businessNameValidation();
                    break;
                }
                case 'email': {
                    emailValidation();
                    break;
                }
                case 'identifier': {
                    identifierValidation();
                    break;
                }

                default: {
                    const successArray: boolean[] = [];

                    nameValidation();
                    businessNameValidation();
                    emailValidation();
                    identifierValidation();
                    passwordValidation();

                    for (const key in this[formName]) {
                        successArray.push((this[formName] as any)[key].error);
                    }

                    return successArray.every(item => !item);
                }
            }
        }
    }

    /**
     * submit sign in
     * 
     * @param {string} formName
     */
    submit(formName: string) {
        let data: RequestBody = {
            FirstName: '',
            LastName: null,
            MiddleName: null,
            BusinessName: '',
            AccountId: '',
            Identifier: '',
            IdentifierType: '',
            Email: '',
            Password: '',
            Language: null,
        };
        this.disableButton = true;

        if (this.validation(formName)) {
            data = {
                FirstName: this.legalEntityForm.name.value,
                LastName: null,
                MiddleName: null,
                BusinessName: this.legalEntityForm.businessName.value,
                AccountId: this.legalEntityForm.accountId.value,
                Identifier: this.legalEntityForm.tin.value,
                IdentifierType: 'FEIN',
                Email: this.legalEntityForm.email.value,
                Password: this.legalEntityForm.password.value,
                Language: this.langs.selected.id ? this.langs.selected.id : null,
            };

            axios.post(this.serverUrl + '/AuthService/Registration', data).then((response) => {
                this.disableButton = false;
                this.$router.push({ path: '/email-verification', query: {email: `${data.Email}` } })
            }).catch((error) => {
                this.disableButton = false;

                if (error.response.status === 409) {
                    this.legalEntityForm.responseError = 'A user with such E-mail already exists.';
                } else {
                    this.legalEntityForm.responseError = 'Something went wrong';
                }
            });
        } else {
            this.disableButton = false;
        }
    }

    created() {
        this.resetFormData();

        this.setStoredAuthParams();
    }
}
