import { render, staticRenderFns } from "./VerificationApp.vue?vue&type=template&id=2380320c&scoped=true&"
import script from "./VerificationApp.vue?vue&type=script&lang=ts&"
export * from "./VerificationApp.vue?vue&type=script&lang=ts&"
import style0 from "./VerificationApp.vue?vue&type=style&index=0&id=2380320c&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2380320c",
  null
  
)

export default component.exports