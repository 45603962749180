
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Redirect extends Vue {

    get serverUrl() {
        return this.$store.getters.getServerUrl;
    }
    get applicationId() {
        return this.$route.params.applicationId;
    }

    /**
     * set field in cookie
     * 
     * @param {string} name 
     * @param {string} value
     */
    setCookie(name: string, value: string) {
        const today = new Date();
        const expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000);

        document.cookie = name + "=" + escape(value) + "; path=/; expires=" + expiry.toUTCString();
    }
    redirectToLogin() {
        this.$router.push({
            name: 'auth',
        });
    }
    redirectToVerify(email: string) {
        this.$router.push({ path: '/email-verification?email=' + email + '&prev=true' })
    }
    redirectToSignUp(identifierType: string) {
        this.$router.push({ path: '/signup?signUpType=' + identifierType });
    }
    prefillRedirect() {
        this.axios.get(this.serverUrl + '/Landing/Application?applicationId=' + this.applicationId)
            .then((response) => {
                const data = response.data;
                const status = data.status;
                const identifierArr = data.identifiers && data.identifiers.length
                    ? data.identifiers.filter((item: any) => item.type === 'TaxID')
                    : [];
                const identifier = identifierArr.length
                    ? identifierArr[identifierArr.length - 1].value
                    : '';
                const identifierType = identifier.length === 8
                    ? 'edrpou'
                    : identifier.length === 10
                        ? 'tin'
                        : 'unknown'
                const prefillData = {
                    email: data.email,
                    companyName: data.companyName,
                    lastName: data.lastName,
                    firstName: data.firstName,
                    middleName: data.middleName,
                    identifier,
                }

                if (status === 2) {
                    this.setCookie('email', data.email);
                    this.redirectToLogin();
                } else if (status === 1) {
                    this.redirectToVerify(data.email);
                } else if (status === 0) {
                    localStorage.setItem('fsnb_sign-in_data', JSON.stringify(prefillData));

                    this.redirectToSignUp(identifierType);
                } else {
                    this.redirectToLogin();
                }
            });
    }

    created() {
        if (this.applicationId) {
            this.prefillRedirect();
        } else {
            this.$router.push({
                name: 'auth',
            });
        }
    }
}
