
import { Prop, Watch, Component } from 'vue-property-decorator';
import DescriptionApp from '@/components/DescriptionApp.vue';
import LoginApp from '@/components/LoginApp.vue';
import LocaleMixin from '@/mixins/locale';

declare interface AuthObject {
    mobilePhone: string;
    name?: string;
    firstName?: string;
    lastName?: string;
    middleName?: string;
    email: string;
    identifierType: string;
    identifier: string;
}

@Component({
    components: {
        DescriptionApp,
        LoginApp,
    },
})
export default class Login extends LocaleMixin {
    @Prop({ default: '' }) token!: string;
    @Prop({ default: '' }) status!: string;

    changeLang: boolean = false;
    storedAuthData: AuthObject | null = null;

    get serverUrl() {
        return this.$store.getters.getServerUrl;
    }

    get redirectUrl() {
        return this.$store.getters.getRedirectUrl;
    }
}
