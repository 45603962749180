
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class TwoStemVerificationModal extends Vue {
  @Prop({ default: '' }) email!: string;

  sendAgain: boolean = false;

}
