
import { Prop, Watch, Component, Vue } from 'vue-property-decorator';
import ServicesModal from '@/components/ServicesModal.vue';
import PrivacyPolicy from '@/components/PrivacyPolicy.vue';
import TermsConditions from '@/components/TermsConditions.vue';

@Component({
    components: {
        ServicesModal,
        PrivacyPolicy,
        TermsConditions,
    },
})
export default class AuthorizationPage extends Vue {
    showModalPrivacy: boolean = false;
    showModalTerms: boolean = false;

    get serverUrl() {
        return this.$store.getters.getServerUrl;
    }

    created() {
        if (this.$route.query.name && typeof this.$route.query.name === "string") {
            localStorage.setItem('businessName', this.$route.query.name);
        }
        if (this.$route.query.email && typeof this.$route.query.email === "string") {
            localStorage.setItem('email', this.$route.query.email);
        }
        if (this.$route.query.id && typeof this.$route.query.id === "string") {
            localStorage.setItem('accountId', this.$route.query.id);
        }
    }
}
