import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// test: https://upswot-docker.upswot.com/fsnb_admin
// prod: https://fsnb.creditpush.com/admin

export default new Vuex.Store({
    state: {
        systems: null,
        serverUrl: 'https://fsnb.creditpush.com/admin/api',
        redirectUrl: 'https://fsnb.creditpush.com/admin',
        maintenanceTemplate: '',
    },
    getters: {
        getSystems(state: any) {
            return state.systems;
        },
        getServerUrl(state: any) {
            return state.serverUrl;
        },
        getRedirectUrl(state: any) {
            return state.redirectUrl;
        },
        getMaintenanceTemplate(state: any) {
            return state.maintenanceTemplate;
        },
    },
    mutations: {
        setSystems(state: any, polyload: any) {
            return state.systems = polyload;
        },
        setServerUrl(state: any, polyload: any) {
            return state.serverUrl = polyload;
        },
        setMaintenanceTemplate(state: any, polyload: any) {
            return state.maintenanceTemplate = polyload;
        },
    },
    actions: {
    }
})
