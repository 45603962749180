
import { Prop, Watch, Component, Vue } from 'vue-property-decorator';
import DescriptionApp from '@/components/DescriptionApp.vue';
import SignUp from '@/components/SignUp.vue';
import LocaleMixin from '@/mixins/locale';

@Component({
    components: {
        DescriptionApp,
        SignUp,
    },
})
export default class Registration extends LocaleMixin {
    @Prop({ default: '' }) signUpType!: string;

    changeLang: boolean = false;

    get serverUrl() {
        return this.$store.getters.getServerUrl;
    }
}
