
import { Prop, Watch, Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

declare interface ForgotPassForm {
    email: {
        value: string;
        error: boolean;
        errorLabel: string;
    }
}
declare interface PassForm {
    password: {
        value: string;
        error: boolean;
        errorLabel: string;
        inputType: string;
    },
    confirmPassword: {
        value: string;
        error: boolean;
        errorLabel: string;
        inputType: string;
    }
}
@Component
export default class ForgotApp extends Vue {
    @Prop({ default: '' }) serverUrl!: string;
    @Prop({ default: '' }) userId!: string;
    @Prop({ default: '' }) email!: string;

    disableButton: boolean = false;
    success: boolean = false;
    responseMessage: string = '';
    formData: ForgotPassForm = {
        email: {
            value: '',
            error: false,
            errorLabel: '',
        },
    };
    passForm: PassForm = {
        password: {
            value: '',
            error: false,
            errorLabel: '',
            inputType: 'password',
        },
        confirmPassword: {
            value: '',
            error: false,
            errorLabel: '',
            inputType: 'password',
        },
    };

    /**
     * validation email field
     * 
     * @param {string} email
     * @return {boolean} result
     */
    validEmail(email: string) {
        const re = /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
     * validation method for email field
     * 
     * @param {string} input
     * @return {boolean | undefined} result
     */
    validate(input?: string) {
        const emailValidation = () => {
            if (!this.formData.email.value) {
                this.formData.email.error = true;
                this.formData.email.errorLabel = 'Field is required.';
            } else if (!this.validEmail(this.formData.email.value)) {
                this.formData.email.error = true;
                this.formData.email.errorLabel = 'Invalid E-mail format.';
            } else {
                this.formData.email.error = false;
                this.formData.email.errorLabel = '';
            }
        }
        switch (input) {
            case 'email': {
                emailValidation();
                break;
            }
            default: {
                emailValidation();

                return !this.formData.email.error;
            }
        }
    }

    /**
     * validation for password recovery
     * 
     * @param {string} input? specific input
     */
    validatePass(input?: string) {
        const passwordValidation = () => {
            const validationArray = [
                ((this.passForm.password.value as string).length >= 8),
                /[A-Z]/.test(this.passForm.password.value as string),
                /[a-z]/.test(this.passForm.password.value as string),
                /[0-9]/.test(this.passForm.password.value as string),
                /[!@#$_.,]/.test(this.passForm.password.value as string),
            ];

            if (!this.passForm.password.value) {
                this.passForm.password.error = true;
                this.passForm.password.errorLabel = 'Field is required.';
            } else if (!validationArray.every(item => item)) {
                this.passForm.password.error = true;
                this.passForm.password.errorLabel = 'Password must contain: 8 or more characters, uppercase and lowercase Latin letters, numbers, special characters (! @ # $ _.,).';
            } else {
                this.passForm.password.error = false;
                this.passForm.password.errorLabel = '';
            }
        }

        const passwordMatch = () => {
            if (this.passForm.password.value !== this.passForm.confirmPassword.value) {
                this.passForm.confirmPassword.error = true;
                this.passForm.confirmPassword.errorLabel = 'Passwords do not match';
            } else {
                this.passForm.confirmPassword.error = false;
                this.passForm.confirmPassword.errorLabel = '';
            }
        }

        switch (input) {
            case 'password': {
                passwordValidation();
                break;
            }
            case 'confirmPassword': {
                passwordMatch();
                break;
            }
            default: {
                const successArray: boolean[] = [];

                passwordMatch();
                passwordValidation();

                for (const key in this.passForm) {
                    successArray.push((this.passForm as any)[key].error);
                }

                return successArray.every(item => !item);
            }
        }
    }

    /**
     * change password submit method
     */
    submitPasswordChange() {
        this.disableButton = true;

        if (this.validatePass()) {
            axios.post(this.serverUrl + '/authservice/updatepassword?token=' + this.userId, { "Password": this.passForm.password.value })
                .then(() => {
                    this.success = true;
                    this.responseMessage = 'Password successfully changed.';
                    this.disableButton = false;

                    this.passForm.password.value = '';
                    this.passForm.confirmPassword.value = '';

                    setTimeout(() => {
                        this.$router.push({ name: 'auth' });
                    }, 3000);
                }).catch((error) => {
                    this.success = false;
                    this.disableButton = false;
                    this.responseMessage = 'Something went wrong';
                });
        }
    }

    /**
     * send email letter with redirect link
     */
    submitEmailLetter() {
        this.disableButton = true;

        if (this.validate()) {
            axios.get(this.serverUrl + '/authservice/changepassword?email=' + this.formData.email.value).then(() => {
                this.$router.push({ name: 'password-recovery', query: {email: this.formData.email.value }});
            }).catch((error) => {
                this.success = false;
                this.disableButton = false;
                if (error.response.status === 401) {
                    this.responseMessage = 'This email is invalid or has expired';
                } else {
                    this.responseMessage = 'Something went wrong';
                }
            });
        } else {
            this.disableButton = false;
        }
    }
}
