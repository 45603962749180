
import { Prop, Component, Vue } from 'vue-property-decorator';
import DescriptionApp from '@/components/DescriptionApp.vue';
import ForgotApp from '@/components/ForgotApp.vue';

@Component({
    components: {
        DescriptionApp,
        ForgotApp,
    },
})
export default class ForgotPass extends Vue {
    @Prop({ default: null }) userId!: string;
    @Prop({ default: 'example@gmail.com' }) email!: string;

    changeLang: boolean = false;

    get serverUrl() {
        return this.$store.getters.getServerUrl;
    }
}
