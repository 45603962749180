
import { Prop, Component, Vue } from 'vue-property-decorator';
import axios from 'axios';
import TwoStemVerificationModal from '@/components/TwoStemVerificationModal.vue';

declare interface LoginForm {
    login: {
        value: string;
        error: boolean;
        errorLabel: string;
    },
    password: {
        value: string;
        inputType: string;
        error: boolean;
        errorLabel: string;
    },
    rememberLogin: boolean;
};
declare interface AuthObject {
    mobilePhone: string;
    name: string;
    email: string;
    identifierType: string;
    identifier: string;
};

@Component({
    components: {
        TwoStemVerificationModal,
    }
})
export default class LoginApp extends Vue {
    @Prop({ default: '' }) serverUrl!: string;
    @Prop({ default: '' }) redirectUrl!: string;
    @Prop({ default: '' }) token!: string;
    @Prop({ default: '' }) storedAuth!: AuthObject | null;

    formData: LoginForm = {
        login: {
            value: '',
            error: false,
            errorLabel: '',
        },
        password: {
            value: '',
            inputType: 'password',
            error: false,
            errorLabel: '',
        },
        rememberLogin: false,
    }
    responseError: string = '';
    disableButton: boolean = false;
    successSignUp: boolean = false;
    errorVerifycation: boolean = false;
    isInner: boolean = false;
    isTwoStepVerifyModal: boolean = false;

    /**
     * validation email field
     * 
     * @param {string} email
     * @return {boolean} result
     */
    validEmail(email: string) {
        const re = /^(([^<>()\[\]\\.,;:\s@“]+(\.[^<>()\[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    /**
     * validation method for all fields
     * 
     * @param {string} input? specific input
     * @return {boolean | undefined} result
     */
    validate(input?: string) {
        const passwordValidation = () => {
            if (!this.formData.password.value) {
                this.formData.password.error = true;
                this.formData.password.errorLabel = 'Invalid password';
            } else {
                this.formData.password.error = false;
                this.formData.password.errorLabel = '';
            };
        }
        const loginValidation = () => {
            if (!this.formData.login.value) {
                this.formData.login.error = true;
                this.formData.login.errorLabel = 'Field is required.';
            } else if (!this.validEmail(this.formData.login.value)) {
                this.formData.login.error = true;
                this.formData.login.errorLabel = 'This mail is invalid or has expired';
            } else {
                this.formData.login.error = false;
                this.formData.login.errorLabel = '';
            }
        }
        switch (input) {
            case 'login': {
                loginValidation();
                break;
            }
            case 'password': {
                passwordValidation();
                break;
            }
            default: {
                const successArray: boolean[] = [];

                loginValidation();
                passwordValidation();

                for (const key in this.formData) {
                    successArray.push((this.formData as any)[key].error);
                }

                return successArray.every(item => !item);
            }
        }
    }

    /**
     * set field in cookie
     * 
     * @param {string} name 
     * @param {string} value
     */
    setCookie(name: string, value: string) {
        const today = new Date();
        const expiry = new Date(today.getTime() + 30 * 24 * 3600 * 1000);

        document.cookie = name + "=" + escape(value) + "; path=/; expires=" + expiry.toUTCString();
    }

    /**
     * get cookie field
     * 
     * @param {string} name
     * @return {string} value
     */
    getCookie(name: string) {
        var re = new RegExp(name + "=([^;]+)");
        var value = re.exec(document.cookie);
        return (value != null) ? unescape(value[1]) : null;
    }

    /**
     * login method
     */
    submit() {
        this.disableButton = true;

        if (this.validate()) {
            const qs = require('qs');

            axios.create({ withCredentials: true }).post(
                this.serverUrl + '/AuthService/Login',
                qs.stringify({
                    'Email': this.formData.login.value,
                    'Password': this.formData.password.value,
                }),
            )
                .then((response) => {
                    if (this.formData.rememberLogin) {
                        this.setCookie('email', this.formData.login.value);
                    }

                    const token = response.data.access_token;
                    const refreshToken = response.data.refreshToken
                    const redirectUrl = response.data ? response.data.redirect_url : '';
                    const redirectCondition = redirectUrl
                        && (redirectUrl.includes('http://') || redirectUrl.includes('https://'));
                    const twoStepVerify = response.data.twoStepVerify;

                    if (token && refreshToken) {
                        localStorage.setItem('token', token);
                        localStorage.setItem('refreshToken', refreshToken);
                        localStorage.setItem('standardSigninEmail', this.formData.login.value);
                        // tslint:disable-next-line:no-string-literal
                        this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    }

                    if (twoStepVerify) {
                        this.toggleTwoStepVerifyModal();
                        this.sendVerificationEmail();
                    } else {
                        window.location.href = redirectCondition
                            ? redirectUrl
                            : this.redirectUrl + '/#/dashboard';
                    }
                })
                .catch((error) => {
                    const errorMessage = error.response.data.Message;

                    this.disableButton = false;
                    if (error.response.status === 403) {
                        this.responseError = 'Access Denied';
                    } else if (error.response.status === 503) {
                        this.$store.commit('setMaintenanceTemplate', errorMessage);
                        this.$router.push('/maintenance');
                    } else if (error.response.status === 423) {
                        this.responseError = `User locked by time: ${error.response.data.Details}`;
                    } else {
                        switch (error.response.status) {
                            case 401:
                                this.responseError = 'Wrong login or password';
                                break;
                            default:
                                this.responseError = 'Authorization error';
                        }
                    };
                })
        } else {
            this.disableButton = false;
        }
    }

    /**
     * getting verify user if there is a token
     */
    verifyUser() {
        axios.get(this.serverUrl + '/AuthService/VerifyUser?token=' + this.token).then(() => {
            this.successSignUp = true;

            setTimeout(() => {
                this.successSignUp = false;
            }, 5000);
        }).catch(() => {
            this.errorVerifycation = true;

            setTimeout(() => {
                this.errorVerifycation = false;
            }, 5000);
        });
    }

    toggleTwoStepVerifyModal() {
        this.isTwoStepVerifyModal = !this.isTwoStepVerifyModal;

        if (this.isTwoStepVerifyModal) {
        document.documentElement.style.overflow = "hidden";
        } else {
        document.documentElement.style.overflow = "auto";
        }
    }
  
    sendVerificationEmail() {
        axios.post(this.serverUrl + '/v1/auth/send-verify', { email: this.formData.login.value });
    }

    created() {
        const rememberedEmail = this.getCookie('email');
        const storedEmail = this.storedAuth ? this.storedAuth.email : null;

        if (rememberedEmail && !storedEmail) {
            this.formData.login.value = rememberedEmail as string;
        } else if (storedEmail) {
            this.formData.login.value = storedEmail;
        }

        if (this.token) {
            this.verifyUser();
        }

        if (this.$route.name === 'inner') {
            this.isInner = true;
        }
    }
}
